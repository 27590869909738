import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import dynamic from "next/dynamic";
import { Fragment } from "react";
import Next from '@mui/icons-material/ArrowForward';
import Back from '@mui/icons-material/ArrowBack';

const ProductCard = dynamic(() => import("@components/Components/Reusables/ProductCard"))
const CategoryProductTile = dynamic(() => import("../../CategoryComponent/Products/CategoryProductTile"))
const Slider = dynamic(() => import("react-slick"));



const slickPrev = {
    fontSize: 0,
    lineHeight: 0,
    position: 'absolute',
    top: '35%',
    display: 'block',
    width: '35px',
    height: '35px',
    padding: 0,
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    zIndex: 2,
	transition: 'all 0.2s ease-in-out',
    ':hover': {
		backgroundColor: 'primary.main',
		color: 'primary.contrastText',
    },
	borderRadius: 0
}
  
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IconButton
		className="slick-arrow-next"
        sx={{ 
            ...slickPrev,
        }}
        onClick={onClick}
      >
        <Next />
      </IconButton>
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IconButton
        className={"slick-arrow-prev"}
        sx={{ 
            ...slickPrev,
        }}
        onClick={onClick}
      >
        <Back />
      </IconButton>
    );
  }
  

var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: true,
    // autoplay: true,
    touchThreshhold: 100,
    speed: 500,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow:  4,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        }
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow:  3,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          // rows:2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:  2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          // rows:2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        }
      },
    ],
}
const ProductCarousel = ({ productData, products, long_term, sectionID = null }) => {


  // const newProduct = [...products, ...products]

    

    
    return (
        <Box 
			sx={{
				':hover': {
					'& .slick-arrow-prev': {
						opacity: 1,
						left: '18px',
					},
					'& .slick-arrow-next': {
						opacity: 1,
						right: '18px',
					}
				},
				
				'.slick-slide > div:first-of-type': {
					padding: {
						xs: '4px 4px 4px 4px',
						lg: '4px 4px 4px 4px',
					}
				},
				'& .slick-arrow-prev': {
					left: '8px',
					opacity: 0
				},
				'& .slick-arrow-next': {
					right: '8px',
					opacity: 0
				},
        '.slick-initialized': {
          paddingLeft: {
            xs: 0,
            md: '8px'
          },
          paddingRight: {
            xs: 0,
            md: '12px'
          }
        }
			}}
		>
			<Slider  {...settings}>
				{products.map((product, idx) => (
					<Fragment key={product.product_id}>
						<CategoryProductTile
								long_term={long_term}
								props={productData}
								product={product}
								source={"home"}
                sectionID={sectionID}
                gridProps={{
                  xs: 12
                }}
						/>
					</Fragment>
				))}
			</Slider>
        </Box>
    );
};

export default ProductCarousel;
